import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./lib/ScrollToTop";
import SideNav from "./components/Navbar/SideNav";
import Footer from "./components/Footer/Footer";
import LandingPage from "./pages/Landing/LandingPage";
import AboutPage from "./pages/About/AboutPage";
import ServicePage from "./pages/Services/ServicesPage";
import ContactPage from "./pages/Contact/ContactPage";
import RealEstatePage from "./pages/RealEstate/RealEstatePage";
import SandDredgingPage from "./pages/SandDreging/SandDredgingPage";
import QuarryPage from "./pages/Quarry/QuarryPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <SideNav />
        <Routes>
          {/* Route for Landing Page */}
          <Route
            path="/"
            element={
              <>
                <LandingPage />
              </>
            }
          />

          {/* Route for About Page */}
          <Route path="/about" element={<AboutPage />} />

          {/* Route for Services Page */}
          <Route path="/services" element={<ServicePage />} />
          <Route path="/services/real-estate" element={<RealEstatePage />} />
          <Route
            path="/services/sand-dredging"
            element={<SandDredgingPage />}
          />
          <Route
            path="/services/quarry"
            element={<QuarryPage />}
          />
          {/* Route for Contact Page */}
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
